import React from 'react';

import { createBrowserRouter } from 'react-router-dom';

import {
  Landing, NotFound, Error,
} from './screens';

const router = createBrowserRouter([
  {
    element: <Landing />,
    path: '/',
    errorElement: <Error />,
  },
  {
    path: '*',
    element: <NotFound />,
  },
]);

export default router;
