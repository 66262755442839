import * as echarts from 'echarts';
import type { EChartsOption } from 'echarts';
import { t } from 'i18next';

const ROOT_PATH = '/charts';

const loadChart = async () => {
  const rawData = await fetch(
    `${ROOT_PATH}/welcome-race.json`,
  ).then((res) => Promise.resolve(res.json()))
    .then((data) => data).catch(() => Promise.resolve({}));

  rawData.map((d: number[], i: number) => {
    if (i > 0) {
      const d2 = d;
      d2[4] += 10;
      return d2;
    }
    return d;
  });

  const categories = [
    'Camion divers(s)',
    'Semi-remorque(s)',
    'Voiture(s)',
    'Suv(s)',
    'Personne(s)',
    'Container(s)',
    // 'Non classé(s)',
    'Bus(s)',
  ];

  const datasetWithFilters: echarts.DatasetComponentOption[] = [];

  const seriesList: echarts.SeriesOption[] = [];
  echarts.util.each(categories, (category) => {
    const datasetId = `dataset_${category}`;
    datasetWithFilters.push({
      id: datasetId,
      fromDatasetId: 'dataset_raw',
      transform: {
        type: 'filter',
        config: {
          and: [
            { dimension: 'Year', gte: 1950 },
            { dimension: 'Category', '=': category },
          ],
        },
      },
    });
    seriesList.push({
      type: 'line',
      datasetId,
      showSymbol: false,
      name: category,
      endLabel: {
        show: true,
        formatter(params: any) {
          return `${t(`landing.welcome-chart.${params.value[3]}`)}: ${params.value[0]}`;
        },
      },
      labelLayout: {
        moveOverlap: 'shiftY',
      },
      emphasis: {
        focus: 'series',
      },
      encode: {
        x: 'Year',
        y: 'Count',
        label: ['Category', 'Count'],
        itemName: 'Year',
        tooltip: ['Count'],
      },
    });
  });
  const chartOptions: EChartsOption = {
    animationDuration: 10000,
    // backgroundColor: 'black',
    textStyle: {
      color: 'black',
    },
    dataset: [
      {
        id: 'dataset_raw',
        source: rawData,
      },
      ...datasetWithFilters,
    ],
    // title: {
    //   text: t('landing.welcome-msg-1'),
    //   textStyle: {
    //     fontWeight: 'bolder',
    //     fontSize: '2em',
    //     color: 'black',
    //   },
    // },
    tooltip: {
      order: 'valueDesc',
      trigger: 'axis',
    },
    xAxis: {
      type: 'category',
      nameLocation: 'end',
    },
    yAxis: {
      name: t('landing.welcome-chart.scale'),
      show: true,
    },
    grid: {
      right: 140,
      top: 100,
    },
    series: seriesList,
  };
  return chartOptions;
};

export { loadChart };
