import React, { FormEvent, useCallback, useState } from 'react';

import { ArrowUpOnSquareIcon, ArrowUpRightIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export const GetInTouch = () => {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);
  const [error, setError] = useState('');
  const [contacted] = useState(false);

  const [form, setForm] = useState<any>({
    fullname: '',
    organization: '',
    email: '',
    phonenumber: '',
    subject: 'GetInTouch',
    message: '',
  });

  const handleChange = (
    event: React.ChangeEvent<HTMLInputElement | HTMLTextAreaElement>,
    key: string,
  ) => setForm((s: any) => ({ ...s, [key]: event.target.value }));

  const handleSubmit = useCallback(async (event: FormEvent) => {
    event.preventDefault();
    setLoading(true);
    setError('');
    setLoading(false);
  }, []);

  return (
    <div id="get-in-touch" className="hero min-h-fit py-10">
      <div className="hero-content flex-col lg:flex-row-reverse gap-10">
        <div className="text-center lg:text-left font-thin">
          <h1 className="text-5xl font-bold">{ t(contacted ? 'landing.title-7' : 'landing.get-in-touch')}</h1>
          <p className="py-6 text-xl">{t(contacted ? 'landing.subtitle-7' : 'landing.subtitle-5')}</p>
          <a className="font-thin text-lg inline-flex gap-2 mx-2 mt-8" href="https://viize.ca" target="_blank" rel="noreferrer">
            { t('landing.more-viize')}
            {' '}
            <span className="text-primary inline-flex gap-1 font-semibold">
              Viize.ca
              <ArrowUpRightIcon className="h-3 w-3" />
            </span>

          </a>
        </div>
        { !contacted && (
          <div className="card bg-gray-300 shrink-0 w-full max-w-sm shadow-lg rounded-none">
            <form onSubmit={handleSubmit} className="card-body">
              <div className="form-control">
                <label htmlFor="fullname" className="label">
                  <input maxLength={40} value={form.fullname} onChange={(e) => handleChange(e, 'fullname')} type="text" name="fullname" placeholder={t('form.get-in-touch.fullname')} className="input input-bordered w-full" required />
                </label>
              </div>
              <div className="form-control">
                <label htmlFor="organization" className="label">
                  <input maxLength={60} value={form.organization} onChange={(e) => handleChange(e, 'organization')} type="text" name="organization" placeholder={t('form.get-in-touch.organization')} className="input input-bordered w-full" required />
                </label>
              </div>
              <div className="form-control">
                <label htmlFor="email" className="label">
                  <input maxLength={40} value={form.email} onChange={(e) => handleChange(e, 'email')} type="email" name="email" placeholder={t('form.get-in-touch.email')} className="input input-bordered w-full" required />
                </label>
              </div>
              <div className="form-control">
                <label htmlFor="phonenumber" className="label">
                  <input maxLength={30} value={form.phonenumber} onChange={(e) => handleChange(e, 'phonenumber')} type="tel" name="phonenumber" placeholder={t('form.get-in-touch.phonenumber')} className="input input-bordered w-full" />
                </label>
              </div>
              <div className="form-control">
                <label htmlFor="message" className="label">
                  <textarea maxLength={250} rows={2} value={form.message} onChange={(e) => handleChange(e, 'message')} name="message" placeholder={t('form.get-in-touch.message')} className="textarea textarea-bordered w-full" />
                </label>
              </div>
              {error && <p className="text-error">{error}</p>}
              <div className="form-control mt-6 text-white ">
                <button
                  type="submit"
                  className={`btn btn-primary ${loading ? 'loading' : ''} text-lg normal-case font-normal hover:text-black px-8 border-none rounded-none gap-2`}
                  disabled={loading}
                >
                  {t('form.get-in-touch.contact')}
                  <ArrowUpOnSquareIcon className="h-8 w-8" />
                </button>
              </div>
            </form>
          </div>
        )}
      </div>
    </div>
  );
};
