import React, {
  FC, useEffect, useState,
} from 'react';

import { BoltIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';
import { Link } from 'react-router-dom';
import Typewriter from 'typewriter-effect';

import styles from './.module.css';
import { ECharts } from './Charts';
import Footer from './Footer';
import { LandingHeader } from './LandingHeader';
import { GetInTouch } from './sections/getInTouch';
import { Overview } from './sections/overview';
import { Solutions } from './sections/solutions';
import { loadChart } from './sections/welcomeChart';

const Landing: FC = () => {
  const { t } = useTranslation();
  const [chartOptions, setChartOptions] = useState({});

  useEffect(() => {
    if (Object.keys(chartOptions).length) return;
    loadChart().then(setChartOptions)
      .catch();
  }, [chartOptions]);

  return (
    <div className={styles.container}>
      <LandingHeader />
      <main className={styles.main}>
        {/* <div className="flex flex-col lg:w-6/12 font-bold gap-y-3">
          <p className="text-3xl">Welcome to Viize Traffic</p>
          <p className="text-xl mt-3">Start here</p>
        </div> */}
        <div className={styles.typing}>
          <Typewriter
            onInit={(typewriter) => {
              typewriter.typeString(t('landing.welcome-msg'))
                .start();
            }}
            options={{
              delay: 10,
            }}
          />
        </div>
        <div className="w-11/12 lg:w-6/12 mt-16 lg:mt-0 gap-0 flex flex-col min-h-fit justify-center items-center text-center md:block">
          <p className="text-2xl font-semibold w-ful -mb-14">
            {t('landing.welcome-msg-1')}
          </p>
          <ECharts
            option={chartOptions}
            theme="light"
            style={{
              height: 300,
            }}
            settings={{
              lazyUpdate: true,
            }}
          />
        </div>
        <div className="inline-flex w-full mt-5 md:w-4/6 px-6 justify-center items-center align-middle gap-4">
          <Link to="/">
            <button
              type="button"
              className="btn-xl inline-flex items-center gap-1 text-black hover:bg-gray-900 font-normal hover:text-white py-2 px-8 sm:px-4"
            >
              {t('landing.ask-demo')}
            </button>
          </Link>
          <Link to="/">
            <button
              type="button"
              className="btn-xl inline-flex items-center gap-1 text-white bg-primary hover:bg-gray-900 font-normal hover:text-white py-2 px-8 sm:px-4"
            >
              {t('landing.continue')}
              <BoltIcon className="h-4 w-4 animate-pulse" />
            </button>
          </Link>
        </div>
      </main>
      <Overview />
      <Solutions />
      <GetInTouch />
      <div className="min-h-16 mt-auto">
        <Footer />
      </div>
    </div>
  );
};

export default Landing;
