import React from 'react';

import { Bars3Icon, BoltIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

const AppLogo = ({
  width = 26, height = 20, titleClass, subtitleClass, imageClass, tiny = false, link = '/', ...rest
}: any) => (
  <a href={link} {...rest} className={`inline-flex justify-start align-middle items-center lg:ml-3 gap-0 ${rest.className}`}>
    <img src="/logo.png" alt="Viize" width={width} height={height} className={`${imageClass}`} />
    {!tiny && (
    <p className="text-2xl font-thin inline-flex dark:text-inherit">
      <span className={`text-primary font-semibold ${titleClass}`}>Viize</span>
      <span className={subtitleClass}>Traffic</span>
    </p>
    )}
  </a>
);

export const LandingHeader = () => {
  const { t } = useTranslation();

  return (
    <div id="landing-header" className="navbar z-50 sticky top-0">
      <div className="navbar-start"><AppLogo /></div>

      <div className="navbar-center hidden lg:flex">
        <ul className="menu menu-horizontal [&>*]:rounded-none">
          <li><a className="rounded-none" href="#">{ t('header.landing.overview')}</a></li>
          <li><a className="rounded-none" href="#">{ t('header.landing.solution')}</a></li>
          <li><a className="rounded-none" href="#">{ t('header.landing.get-in-touch')}</a></li>
        </ul>
      </div>
      <div className="navbar-end flex flex-row gap-2 justify-end items-center align-middle">
        <button
          type="button"
          className="btn-sm w-36 hidden lg:inline-flex justify-center items-center gap-1 text-white bg-primary hover:bg-gray-900 font-normal hover:text-white py-2 px-2 borderrounded"
        >
          {t('header.landing.live')}
          <BoltIcon className="h-4 w-4 animate-pulse" />
        </button>
        <div tabIndex={0} role="button" className="btn btn-ghost lg:hidden">
          <Bars3Icon className="h-5 w-5" />
        </div>
      </div>
    </div>
  );
};
