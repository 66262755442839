import React from 'react';

import { InformationCircleIcon } from '@heroicons/react/24/outline';
import { useTranslation } from 'react-i18next';

export const Solutions = () => {
  const { t } = useTranslation();
  return (
    <div id="solutions" className="card shadow-none mt-20 lg:mt-24 align-middle justify-center items-center min-h-fit gap-10">
      <figure><img src="/imgs/viizebox.png" alt="device" className="h-80" /></figure>
      <div className="card-body gap-10 lg:w-10/12">
        <p className="card-title font-normal text-3xl justify-center">
          {t('landing.title-3')}
        </p>
        <p className="font-thin text-2xl justify-center text-center">
          {t('landing.subtitle-3')}
        </p>
        <div className="card-actions justify-center">
          <a href="#" className="btn btn-ghost text-lg font-normal normal-case hover:text-black px-8 border-none rounded-none gap-2">
            {t('header.landing.register')}
          </a>
          <a href="/" className="btn btn-primary text-lg font-normal normal-case hover:text-white px-8 border-none rounded-none gap-2">
            {t('landing.order-and-install')}
            <InformationCircleIcon className="h-6 w-6" />
          </a>
        </div>
      </div>
    </div>
  );
};
